import React from 'react';
import weaccess from './img/weaccess.svg';
import './App.css';

function App(props) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={weaccess} className="App-logo" alt="WeAccess" />
        <div className="Info">
          {props.children}
        </div>
      </header>
    </div>
  );
}

export default App;
