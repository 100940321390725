import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import './App.css'
import QRCode from 'react-qr-code'
import Lottie from 'lottie-react-web'
import loadingAnim from './anim/loading.json'

const IS_ENABLE = gql`
    query GetInvitation(
        $id: bigint!
        $guestName: String!
        $guestLastname: String!
        $userName: String!
        $userLastname: String!
        $unit: String!
    ) {
        invitations(where: {
            _and: {
                guest: {
                    nombre: {
                        _eq: $guestName
                    }, 
                    apellido: {
                        _eq: $guestLastname
                    }
                }, 
                user: {
                    nombre: {
                        _eq: $userName
                    }, 
                    apellido: {
                        _eq: $userLastname
                    }
                }, 
                enable: {
                    _eq: true
                }, 
                id: {
                    _eq: $id
                },
                unit: {
                    numero: {
                        _eq: $unit
                    }
                }
            }
        }) {
            enable
        }
    }
`;

function Timer(props) {
    const [ duration, setDuration ] = useState(Math.round(props.info.duration - ((Date.now()/1000)%props.info.duration)))
    const [ enable ] = useState(props.info.enable)

    useEffect(() => {
        if (duration > 0 && enable) {
            setTimeout(() => {
                setDuration(duration - 1)
            }, 1000)
        }
        else if(enable) {
            window.location.reload()
        }
        else {
            setDuration(0)
        }
    }, [duration, enable])

    return (
        <p className="text">
            {duration > 0 ? props.info.host + ' te ha invitado al ' + props.info.depto + ' de ' + props.info.edificio : ''}
            <br/>
            <b className="time">Tu código</b>
            {duration > 0 ? ' de acceso ' : ''} 
            <b className="time">{duration > 0 ? 'cambiará en ' + duration : ' ya expiró'}</b>
            {duration > 0 ? '' : ', tenés que solicitar uno nuevo'} 
        </p>
    )
}

function Invitation(props) {
    const [ first, setFirst ] = useState(true)
    const [ load, setLoad ] = useState(true)
    const [ totp, setTotp ] = useState('')
    const [ totpInfo, setTotpInfo ] = useState({})
    const params = new URLSearchParams(props.location.search)
    const id = params.get('id')
    const bytes = atob(id)
    const jsonInfo = JSON.parse(bytes.replace(/'/gm, '"'))
    const { loading, data } = useQuery(IS_ENABLE, 
        {
            variables: {
                id: jsonInfo.invitacion,
                guestName: jsonInfo.guest.split(" ")[0], 
                guestLastname: jsonInfo.guest.split(" ")[1],
                userName: jsonInfo.host.split(" ")[0], 
                userLastname: jsonInfo.host.split(" ")[1],
                unit: jsonInfo.depto.toString()
            }
        }
    )
    const options = {
        loop: true,
        autoplay: true,
        animationData: loadingAnim
    }

    useEffect(() => {
        if (first && !loading) {
            setFirst(false)
            fetch("https://totp.sil.weaccess.wehaus.com/totp",{
                method: 'POST',
                body: JSON.stringify(jsonInfo),
                headers:{
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                },
                mode: 'cors'
            })
            .then(response => response.json())
            .then(responseJson => {
                const totpString = atob(responseJson.totp)
                const totpJson = JSON.parse(totpString.replace(/'/gm, '"'))
                setTotpInfo({...totpJson, enable: data.invitations.length !== 0 ? true : false})
                setTotp(btoa(JSON.stringify({invitacion: totpJson.invitacion, totp: totpJson.totp})))
                setTimeout(() => setLoad(false), 1000)
            })
            .catch(error => console.log(error))
        }
    }, [first, jsonInfo, loading])

    return (
        <div className="invitation">
            <h1 className="hello">Hola {jsonInfo.guest}</h1>
            {
                !load ?
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <Timer info={totpInfo}/>
                    <br></br>
                    <div className="qr-code" style={{padding:20}}>
                        <QRCode value={data.invitations.length !== 0 ? totp : "Pedi una nueva invitacion"} level='L'/>
                    </div>
                </div>
                :
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <p className="text">Estamos generando tu código de acceso...</p>
                    <Lottie options={options} />
                </div>
            }
        </div>
    );
}

export default Invitation;
