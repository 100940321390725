import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Invitation from './Invitation';
import * as serviceWorker from './serviceWorker';
import {
  Route,
  HashRouter
} from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

const client = new ApolloClient({
  uri: "https://gql.sil.weaccess.wehaus.com/v1/graphql",
  headers: {
      'x-hasura-admin-secret': '1a61e9a4c69fb2b1c0aa535c0ca9909e0e12a38a57d5098e3deb5608e6d14a0c05e432f680e04fad5016d10cd86d92e90c664c54a4530b5a7045823679f73395'
  }
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <HashRouter>
            <App>
                <Route path='/invitation' component={Invitation} />
            </App>
        </HashRouter>
    </ApolloProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
